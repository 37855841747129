<template>
  <div class="content">
    <section>
      <div class="d-flex">
        <v-spacer></v-spacer>

        <v-btn
          class="mb-4 btn-new"
          dark
          style="background: #50b565;"
          @click="showDialog(null)"
        >
          nueva retención
        </v-btn>
      </div>

      <LayoutPage title="Retenciones">
        <v-data-table
          :headers="headers"
          :items="retenciones"
          :loading="loading"
          loading-text="Cargando retenciones...."
          no-data-text="Sin retenciones"
          class="elevation-0 mt-3 tabla"
          @page-count="pageCount = $event"
          :page.sync="page"
          :items-per-page="rowsPerPage"
          disable-sort
          hide-default-footer
          checkbox-color="green"
        >
          <template v-slot:[`item.condicion`]="{ item }">
            {{
              item.condicion.gravamen.gravamen === "ganancias"
                ? "Ganancias"
                : item.condicion.gravamen.gravamen.toUpperCase()
            }}
          </template>
          <template v-slot:[`item.gravamen`]="{ item }">
            {{ item.condicion.gravamen.descripcion }}
          </template>

          <template v-slot:[`item.minorista`]="{ item }">
            <v-chip :color="item.minorista ? 'green' : 'red'" dark>
              {{ item.minorista ? "Si" : "No" }}
            </v-chip>
          </template>

          <template v-slot:[`item.rg2854`]="{ item }">
            <v-chip :color="item.rg2854 ? 'green' : 'red'" dark>
              {{ item.rg2854 ? "Si" : "No" }}
            </v-chip>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon color="orange darken-1" @click="showDialog(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <!-- PAGINACION -->
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </div>
      </LayoutPage>

      <NuevaRetencion
        ref="dialogNuevaRetencion"
        @refreshData="getRetenciones()"
        @closeDialog="() => (retencionEdit = null)"
      />
    </section>
  </div>
</template>

<script>
import { retencionesService } from "@/services/administrar";
import LayoutPage from "@/components/LayoutPage.vue";
import NuevaRetencion from "./NuevaRetencion.vue";

export default {
  name: "Retenciones",
  components: { LayoutPage, NuevaRetencion },
  data() {
    return {
      loading: false,
      headers: [
        { text: "Impuestos", value: "condicion" },
        { text: "Gravamen descripción", value: "gravamen" },
        { text: "Código regimen", value: "codigo_regimen" },
        {
          text: "Medio de pago",
          value: "canal_de_pago",
        },
        { text: "Alícuota", value: "alicuota" },
        { text: "Alícuota opcional", value: "alicuota_opcional" },
        { text: "RG AFIP 2854", value: "rg2854" },
        { text: "Estac. de servicio", value: "minorista" },
        { text: "Monto mínimo", value: "monto_minimo" },
        { text: "Acciones", value: "actions" },
      ],

      retenciones: [],
      retencionEdit: null,
      cliente: { cliente: "" }, // valor por defectos antes de la llamada

      rowsPerPageArray: [10, 30, 50],
      rowsPerPage: 10, // cantidad de registros que se muestran en la tabla
      page: 1, // pagina actual de la tabla
      pageCount: 0, // cantidad de paginas
    };
  },

  mounted() {
    this.getRetenciones();
  },
  methods: {
    async getRetenciones() {
      try {
        this.loading = true;
        const resp = await retencionesService.getAll();

        this.retenciones = resp.data?.retenciones || [];

        this.loading = false;
      } catch (error) {
        // console.error(error);
        this.loading = false;
      }
    },

    showDialog(retencion) {
      this.retencionEdit = retencion;
      this.$refs.dialogNuevaRetencion.$emit("showDialog", this.retencionEdit);
    },
  },
};
</script>

<style scoped></style>
