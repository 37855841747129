<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="995px"
    @click:outside="handleCloseDialog"
  >
    <v-card min-width="995px">
      <v-toolbar dark>
        <v-toolbar-title style="font-weight: 700;">
          {{ edit ? "Editar" : "Nueva" }} Retención
        </v-toolbar-title>

        <template v-slot:extension>
          <v-btn-toggle
            v-model="tab"
            tile
            color="deep-purple accent-3"
            group
            mandatory
            @change="getCondiciones()"
          >
            <v-btn
              :value="gravamen"
              v-for="gravamen in gravamens"
              :key="gravamen.id"
            >
              {{ gravamen.gravamen }}
            </v-btn>
          </v-btn-toggle>
        </template>
      </v-toolbar>

      <v-card-text style="height: 500px;" class="py-4">
        <section v-if="step === 1">
          <v-list flat subheader three-line>
            <v-subheader>
              Condición fiscal
              {{ tab ? tab.descripcion : "" }}
              <v-spacer></v-spacer>
              <v-text-field
                placeholder="Buscar condición"
                append-icon="mdi-magnify"
                v-model="search"
              ></v-text-field>
            </v-subheader>

            <v-list-item-group v-model="idCondicion">
              <div v-for="(condicion, i) in condiciones" :key="condicion.id">
                <v-list-item class="py-2" :value="condicion.id" three-line>
                  <v-list-item-action class="mr-3">
                    <v-checkbox
                      :input-value="idCondicion == condicion.id"
                      readonly
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="text-capitalize">
                      {{ condicion.condicion }}
                    </v-list-item-title>

                    <v-list-item-subtitle>
                      {{ condicion.descripcion }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon @click="showDialogCondicion(condicion)">
                      <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>

                <v-divider class="my-0" v-if="i !== condiciones.length - 1">
                </v-divider>
              </div>
            </v-list-item-group>

            <p
              class="text-center mt-5 h5 gray--text"
              v-if="condiciones.length === 0 && !loading"
            >
              Sin condiciones
            </p>

            <div v-if="loading">
              <v-skeleton-loader type="list-item-three-line">
              </v-skeleton-loader>
              <v-skeleton-loader type="list-item-three-line">
              </v-skeleton-loader>
            </div>
          </v-list>
          <div class="px-4" style="margin-top: 30px;">
            <v-btn
              block
              text
              style="border: 2px dashed #ddd; padding: 30px 0; color: #aaa;"
              class="btn-add"
              @click="showDialogCondicion()"
            >
              <v-icon class="mx-2">mdi-plus</v-icon>
              Nueva condición
            </v-btn>
          </div>
        </section>

        <section v-if="step === 2">
          <v-list flat subheader>
            <v-subheader>Medios de pago</v-subheader>
            <p class="mx-3 mb-0 mt-2">Seleccione en caso de que corresponda</p>

            <v-list-item-group group="Iva" v-model="idMedioPago" multiple>
              <div v-for="(medio, index) in medio_pago" :key="index">
                <v-list-item class="py-2" :value="medio.Id">
                  <template v-slot:default="{ active }">
                    <v-list-item-action class="mr-3">
                      <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-title>
                      {{ medio.Nombre }}
                    </v-list-item-title>
                  </template>
                </v-list-item>
                <v-divider
                  class="my-0"
                  v-if="index !== medio_pago.length - 1"
                ></v-divider>
              </div>
            </v-list-item-group>
          </v-list>
        </section>

        <section v-if="step === 3">
          <v-list flat subheader two-line>
            <v-subheader>Categoría</v-subheader>

            <v-list-item
              class="py-2"
              @click="
                () => {
                  rg2854 = !rg2854;
                  minorista = false;
                }
              "
            >
              <v-list-item-action class="mr-3">
                <v-checkbox :input-value="rg2854"></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  Sujeto retenido se encuentre en el anexo I RG 2854
                </v-list-item-title>

                <v-list-item-subtitle>
                  Aplica para los sujetos que esten en la resolución general
                  2854 / 2010
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider class="my-0"></v-divider>

            <v-list-item
              class="py-2"
              @click="
                () => {
                  minorista = !minorista;
                  rg2854 = false;
                }
              "
            >
              <v-list-item-action class="mr-3">
                <v-checkbox :input-value="minorista"></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  Estaciones de servicios y bocas de expendio minoristas
                </v-list-item-title>

                <v-list-item-subtitle>
                  Depende de la actividad y puede aclar en la constancia de afip
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </section>

        <section v-if="step === 4">
          <!-- codigo -->
          <v-subheader>Código de régimen</v-subheader>
          <v-text-field
            placeholder="Ingrese un código"
            filled
            type="number"
            class="alicuota mx-4 m"
            hint="Campo obligatorio"
            v-model="codigo"
            persistent-hint
            :error-messages="numberError"
            @input="validateNumber"
            :counter="5"
          ></v-text-field>
          <!-- descripcion -->
          <v-subheader>Descripción</v-subheader>
          <v-textarea
            placeholder="Ingrese una descripción"
            class="mx-4 alicuota"
            hint="Campo opcional"
            v-model="descripcion"
            persistent-hint
            filled
          ></v-textarea>
        </section>

        <section v-if="step === 5">
          <v-row no-gutters>
            <v-col cols="12" lg="6">
              <v-subheader>Alícuota</v-subheader>
              <v-text-field
                placeholder="Ingrese un porcentaje"
                filled
                class="alicuota mx-4"
                hint="Por ejemplo % 0.25"
                type="number"
                persistent-hint
                prefix="%"
                v-model="alicuota"
              >
              </v-text-field>

              <v-expansion-panels class="mt-4">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Alícuota opcional
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-text-field
                      placeholder="Ingrese un porcentaje opcional"
                      filled
                      class="alicuota"
                      hint="Por ejemplo % 0.25"
                      type="number"
                      persistent-hint
                      prefix="%"
                      v-model="alicuotaOpcional"
                    >
                    </v-text-field>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <v-col cols="12" lg="6">
              <v-subheader>Monto mínimo</v-subheader>

              <v-text-field
                placeholder="Ingrese un monto"
                filled
                type="number"
                class="alicuota mx-4"
                v-model="montoMinimo"
                persistent-hint
                hint="Por ejemplo $500"
                prefix="$"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="11" sm="5">
              <v-menu
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Fecha de validez desde"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="FormatDateDesde"
                    persistent-hint
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaValidezDesde"
                  locale="es-ar"
                  no-title
                  scrollable
                  :min="new Date().toISOString().substr(0, 10)"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="11" sm="5">
              <v-menu
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Fecha de validez hasta"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="FormatDateHasta"
                    persistent-hint
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaValidezHasta"
                  locale="es-ar"
                  no-title
                  scrollable
                  :min="fechaValidezDesde"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </section>
      </v-card-text>

      <v-divider class="my-0"></v-divider>
      <v-card-actions class="py-3">
        <v-btn text :disabled="step === 1" @click="step--">VOLVER</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          text
          style="font-weight: 700; font-family: 'Montserrat';font-size: 0.95em;"
          class="body-1"
          @click="step++"
          v-if="step !== 5"
          :disabled="validarForm"
          >CONTINUAR
        </v-btn>
        <v-btn
          @click="guardarRetencion()"
          class="btn-guardar px-3"
          text
          :loading="loading"
          v-else
          >{{ edit ? "Actualizar" : "Guardar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <NuevaCondicion ref="nuevaCondicion" @refreshData="getCondiciones()" />
  </v-dialog>
</template>

<script>
import NuevaCondicion from "./NuevaCondicion.vue";
import { retencionesService } from "@/services/administrar";
import { mapGetters } from "vuex";

export default {
  name: "NuevaRetencion",
  components: { NuevaCondicion },
  data() {
    return {
      dialog: false,
      step: 1,
      tab: null,

      gravamens: [],

      condiciones: [],

      search: "",

      loading: false,
      edit: false,

      numberError: "",

      // datos de la retencion
      idCondicion: null,
      idMedioPago: null,
      rg2854: false,
      minorista: false,
      montoMinimo: 0,
      alicuota: 0,
      alicuotaOpcional: 0,
      descripcion: "",
      codigo: "",
      idEdit: null,

      fechaValidezDesde: moment().format("YYYY-MM-DD"),
      fechaValidezHasta: moment()
        .add(1, "year")
        .format("YYYY-MM-DD"),
    };
  },

  async mounted() {
    this.$on("showDialog", (retencion = null) => {
      this.dialog = true;

      if (retencion) {
        this.edit = true;
        this.gravamens = [retencion.condicion.gravamen];
        this.tab = retencion.condicion.gravamen;
        this.getCondiciones();

        this.idCondicion = retencion.condicion.id;
        this.rg2854 = retencion.rg2854;
        this.minorista = retencion.minorista;
        this.alicuota = retencion.alicuota;
        this.alicuotaOpcional = retencion.alicuota_opcional;
        this.montoMinimo = retencion.monto_minimo;
        this.idEdit = retencion.id;
        this.codigo = retencion.codigo_regimen;
        this.fechaValidezDesde =
          retencion.fecha_validez_desde === "0001-01-01T00:00:00Z"
            ? ""
            : retencion.fecha_validez_desde.slice(0, 10);
        this.fechaValidezHasta =
          retencion.fecha_validez_hasta === "0001-01-01T00:00:00Z"
            ? ""
            : retencion.fecha_validez_hasta.slice(0, 10);

        this.idMedioPago = [retencion.channels_id];
      } else {
        this.getGravamens();
        // this.getCondiciones();
      }
    });
  },

  computed: {
    ...mapGetters("pagos", ["canalespago"]),

    medio_pago() {
      return this.canalespago.filter(
        (c) => c.Nombre !== "OFFLINE" && c.Nombre !== "TODOS"
      );
    },

    validarForm() {
      if (this.step === 1 && !this.idCondicion) return true;

      if (this.step === 2 && !this.idMedioPago) return true;

      if (this.step === 4 && (!this.codigo || this.numberError.length > 0))
        return true;

      return false;
    },

    FormatDateDesde() {
      return this.fechaValidezDesde
        ? moment(this.fechaValidezDesde).format("DD/MM/YYYY")
        : "";
    },
    FormatDateHasta() {
      return this.fechaValidezHasta
        ? moment(this.fechaValidezHasta).format("DD/MM/YYYY")
        : "";
    },
  },

  methods: {
    showDialogCondicion(condicion) {
      this.$refs.nuevaCondicion.$emit("showDialog", {
        gravamen: this.tab,
        condicion,
      });
    },

    async guardarRetencion() {
      try {
        let body = {
          CondicionsId: this.idCondicion,
          ChannelsId: this.idMedioPago,
          Rg2854: this.rg2854,
          Minorista: this.minorista,
          Alicuota: Number(this.alicuota),
          AlicuotaOpcional: Number(this.alicuotaOpcional),
          MontoMinimo: Number(this.montoMinimo),
          Descripcion: this.descripcion,
          CodigoRegimen: this.codigo,
          FechaValidezDesde: this.fechaValidezDesde,
          FechaValidezHasta: this.fechaValidezHasta,
        };

        if (this.edit) body = { ...body, Id: this.idEdit };

        this.loading = true;
        const data = this.edit
          ? await retencionesService.updateRetencion(body)
          : await retencionesService.nuevaRetencion(body);

        this.handleCloseDialog();

        this.loading = false;
        this.dialog = false;
        this.$emit("refreshData");

        this.$toastr.s(data.message);
      } catch (error) {
        // console.error("error: ", error);
        this.$toastr.e(error.response.data.message);
        this.loading = false;
      }
    },

    async getCondiciones() {
      try {
        this.loading = true;
        this.condiciones = [];
        this.step = 1;

        // console.log("gravamen: ", this.tab);
        const params = { GravamensId: this.tab.id, CargarGravamenes: true };
        const data = await retencionesService.getCondiciones(params);

        this.loading = false;
        this.condiciones = data.data;
      } catch (error) {
        // console.error("error: ", error);
        this.$toastr.e("Error al obtener condiciones de retenciones");
        this.loading = false;
      }
    },

    async getGravamens() {
      try {
        this.loading = true;
        const data = await retencionesService.getGravamens();

        this.tab = data?.data[0] || {};
        this.gravamens = data?.data || [];

        this.getCondiciones();
      } catch (error) {
        // console.error("error: ", error);
        this.$toastr.e("Error al obtener al obtener gravamens");
        this.dialog = false;
        this.loading = false;
      }
    },

    handleCloseDialog() {
      this.tab = null;
      this.gravamens = [];
      this.step = 1;

      this.condiciones = [];

      this.idCondicion = null;
      this.idMedioPago = null;
      this.rg2854 = false;
      this.minorista = false;
      this.montoMinimo = 0;
      this.alicuota = 0;
      this.alicuotaOpcional = 0;
      this.fechaValidezDesde = "";
      this.fechaValidezHasta = "";
      this.descripcion = "";
      this.codigo = "";
      this.idEdit = null;
      this.edit = false;

      this.$emit("closeDialog");
    },
    validateNumber() {
      this.numberError = "";

      if (parseInt(this.codigo) <= 0) {
        this.numberError = "El código ingresado debe ser mayor a 0";
      }

      if (this.codigo.length > 5) {
        this.numberError = "El código no debe superar los 5 dígitos";
        // this.codigo = this.codigo.substring(0, 5);
      }
    },
  },
};
</script>

<style scoped>
.btn-add:hover {
  color: #222 !important;
  transition: 0.3s all;
  border-color: #bbb !important;
}

::v-deep.v-tab--active {
  background: #444;
}

.link {
  color: #50b565;
  text-decoration: underline;
}

.alicuota ::v-deep.v-messages__message {
  color: #aaa !important;
}

.alicuota ::v-deep.v-text-field__prefix {
  margin-right: 10px;
}

.check-alicuota ::v-deep.v-label {
  padding-left: 10px;
}

.btn-guardar {
  /* background: #272727 !important; */
  color: #50b565 !important;
  font-weight: 700;
  font-size: 0.9em;
}
</style>
