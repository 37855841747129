import axios from "axios";
export const PASARELA = process.env.VUE_APP_URLPASARELA;

const getAll = async (params) => {
  const { data } = await axios.get(
    PASARELA + "/administracion/user_bloqueado",
    {
      params,
    }
  );
  return data;
};

const newBloqUser = async (payload) => {
  const { data } = await axios.post(
    PASARELA + "/administracion/user_bloqueado",
    payload
  );
  return data;
};

const updateBloqUser = async (payload) => {
  const { data } = await axios.put(
    PASARELA + "/administracion/user_bloqueado",
    payload
  );
  return data;
};

const deleteBloqUser = async (payload) => {
  const { data } = await axios.delete(
    PASARELA + "/administracion/user_bloqueado",
    { data: payload }
  );
  return data;
};

export default {
  getAll,
  newBloqUser,
  updateBloqUser,
  deleteBloqUser,
};
