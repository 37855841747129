import apiBackground from "../apiBackground";

//RETENCIONES
const getDataById = async (params) => {
  const endpoint = "/administracion/cliente-retenciones";
  const { data } = await apiBackground.get(endpoint, {
    params,
  });

  return data;
};

const getAll = async () => {
  const { data } = await apiBackground.get("/administracion/retenciones");

  return data;
};

const nuevaRetencion = async (body) => {
  const endpoint = "administracion/retencion";
  const { data } = await apiBackground.post(endpoint, body);

  return data;
};
const updateRetencion = async (body) => {
  const endpoint = "administracion/retencion";
  const { data } = await apiBackground.put(endpoint, body);

  return data;
};

const asignarRetencion = async (body) => {
  const endpoint = "administracion/asignar-retencion-cliente";
  const { data } = await apiBackground.post(endpoint, body);

  return data;
};

const quitarRetencion = async (params) => {
  const endpoint = "administracion/delete-cliente-retencion";
  const { data } = await apiBackground.delete(endpoint, { params });

  return data;
};

// CONDICIONES
const getCondiciones = async (params) => {
  const endpoint = "/administracion/condiciones";
  const { data } = await apiBackground.get(endpoint, { params });

  return data;
};

const saveCondicion = async (condicion) => {
  const endpoint = "/administracion/condicion";
  const { data } = await apiBackground.post(endpoint, condicion);

  return data;
};

const updateCondicion = async (condicion) => {
  const endpoint = "/administracion/condicion";
  const { data } = await apiBackground.put(endpoint, condicion);

  return data;
};

// CERTIFICADOS
const getCertificado = async (params) => {
  const endpoint = "/administracion/certificado";
  const { data } = await apiBackground.get(endpoint, { params });

  return data;
};

const createCertificado = async (formData) => {
  const endpoint = "/administracion/certificado";
  const { data } = await apiBackground.post(endpoint, formData);

  return data;
};

// GRAVAMENS
const getGravamens = async () => {
  const { data } = await apiBackground.get("/administracion/gravamenes");

  return data;
};

export default {
  //retenciones
  getDataById,
  getAll,
  nuevaRetencion,
  updateRetencion,
  asignarRetencion,
  quitarRetencion,

  //condiciones
  getCondiciones,
  saveCondicion,
  updateCondicion,

  //certificados
  getCertificado,
  createCertificado,

  //gravamens
  getGravamens,
};
