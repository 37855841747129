var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('section',[_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{staticClass:"mb-4 btn-new",staticStyle:{"background":"#50b565"},attrs:{"dark":""},on:{"click":function($event){return _vm.showDialog(null)}}},[_vm._v(" nueva retención ")])],1),_c('LayoutPage',{attrs:{"title":"Retenciones"}},[_c('v-data-table',{staticClass:"elevation-0 mt-3 tabla",attrs:{"headers":_vm.headers,"items":_vm.retenciones,"loading":_vm.loading,"loading-text":"Cargando retenciones....","no-data-text":"Sin retenciones","page":_vm.page,"items-per-page":_vm.rowsPerPage,"disable-sort":"","hide-default-footer":"","checkbox-color":"green"},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.condicion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.condicion.gravamen.gravamen === "ganancias" ? "Ganancias" : item.condicion.gravamen.gravamen.toUpperCase())+" ")]}},{key:"item.gravamen",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.condicion.gravamen.descripcion)+" ")]}},{key:"item.minorista",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.minorista ? 'green' : 'red',"dark":""}},[_vm._v(" "+_vm._s(item.minorista ? "Si" : "No")+" ")])]}},{key:"item.rg2854",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.rg2854 ? 'green' : 'red',"dark":""}},[_vm._v(" "+_vm._s(item.rg2854 ? "Si" : "No")+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"orange darken-1"},on:{"click":function($event){return _vm.showDialog(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1),_c('NuevaRetencion',{ref:"dialogNuevaRetencion",on:{"refreshData":function($event){return _vm.getRetenciones()},"closeDialog":function () { return (_vm.retencionEdit = null); }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }