<template>
  <v-dialog
    v-model="dialog"
    max-width="650px"
    class="dialog"
    @click:outside="handleClose()"
  >
    <v-card>
      <v-card-title style="font-weight: 700;">
        {{ edit ? "Editar" : "Nueva" }} condición
      </v-card-title>
      <v-card-text class="px-4">
        <v-subheader v-if="gravamen">
          Gravamen:
          <span class="text-uppercase mx-2">{{ gravamen.gravamen }}</span>
        </v-subheader>

        <v-form ref="form">
          <v-text-field
            placeholder="Condición"
            :rules="[(v) => !!v || 'La condición es requerida']"
            v-model="condicion.condicion"
            outlined
          ></v-text-field>

          <v-checkbox
            label="Exento"
            class="checkbox"
            v-model="condicion.exento"
            style="margin-top: -5px;"
          ></v-checkbox>

          <v-textarea
            placeholder="Descripción"
            :rules="[(v) => !!v || 'La descripción es requerida']"
            class="mt-3"
            v-model="condicion.descripcion"
            outlined
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          style="font-weight: 700;"
          :loading="loading"
          @click="guardarGravamen()"
        >
          {{ edit ? "Actualizar" : "Guardar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { retencionesService as retencionService } from "@/services/administrar";

export default {
  name: "NuevaCondicion",
  data() {
    return {
      dialog: false,
      edit: false,
      gravamen: null,

      condicion: { condicion: "", exento: false, descripcion: "", id: "" },

      loading: false,
    };
  },

  mounted() {
    this.$on("showDialog", ({ gravamen, condicion }) => {
      this.dialog = true;
      this.gravamen = gravamen;

      if (condicion) {
        this.edit = true;
        this.condicion = condicion;
      }
    });
  },

  methods: {
    async guardarGravamen() {
      if (this.$refs.form.validate()) {
        try {
          // datos que van a ser enviados :send
          let data = {
            GravamensId: this.gravamen.id,
            Descripcion: this.condicion.descripcion,
            Condicion: this.condicion.condicion,
            Exento: this.condicion.exento,
          };

          if (this.condicion.id) data = { ...data, Id: this.condicion.id };

          this.loading = true;

          const resp = this.edit
            ? await retencionService.updateCondicion(data) // si se desea editar --> llamada endpoint update
            : await retencionService.saveCondicion(data); // en caso contrario --> llamda enpoint create

          this.loading = false;
          this.dialog = false; // cerrando dialog

          // reset formulario
          this.handleClose();
          this.$emit("refreshData");

          // mostrando mensaje de exito ✅
          this.$toastr.s(resp.message);
        } catch (error) {
          // mostrando mensaje de error en consola 🔇
          // console.error(error);

          this.$toastr.e("Error al guardar la condición");
          this.loading = false;
        }
      }
    },

    handleClose() {
      this.edit = false;
      this.condicion = { condicion: "", descripcion: "", id: "" };
      this.gravamen = false;
    },
  },
};
</script>

<style scoped></style>
