import axios from "axios";

const baseURL = process.env.VUE_APP_URLBACKGROUND;
const apiBackground = axios.create({ baseURL });

apiBackground.interceptors.request.use(
  (config) => {
     const token = localStorage.getItem("token"); // Obtén el token de tu almacenamiento local
   
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Agrega el token a la cabecera de la solicitud
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiBackground;
