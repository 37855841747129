import apiPasarela from "../apiPasarela";

const getClientById = async (clienteData) => {
  const endpoint = "/administracion/obtener-cliente";
  const { data } = await apiPasarela.post(endpoint, clienteData);

  return data;
};

const obtenerClientes = async (params) => {
  const endpoint = "/administracion/clientes";
  const { data } = await apiPasarela.get(endpoint, {params});
  // console.log(data)

  return data;
};


export default { getClientById, obtenerClientes };
